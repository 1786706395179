.checkbox {
    display: flex;
    // display: grid;
    // align-content: center;
    // font-size: 30px;
    cursor: pointer;
    user-select: none;

    // .icon--Check_box-Empty,
    // .icon--Check_box-Mark {
    //     opacity: 1;
    //     grid-column-start: 1;
    //     grid-row-start: 1;
    //     opacity: 0;

    //     &.active {
    //         opacity: 1;
    //     }
    // }
}