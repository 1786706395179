@import 'styles/general/variables.scss';
$help-bubble--triangle--width: 0.5em;

@mixin help_bubble_triangle_style{
    content: "";
    position: absolute;
    border-width: $help-bubble--triangle--width;
    border-style: solid;
}

.help_button_container{
    &:hover{
        border-radius: 6px;
    }
    .help_button {
        width: fit-content;
        &.icon--help {
            filter: var(--filter--green--1);
            &:hover {
                cursor: pointer;
            }
        }
    }
}

.help_bubble {
    border-radius: 0.5em;
    padding: 1em;
    position: fixed;
    z-index: $index--tooltip;
    margin: $help-bubble--triangle--width;
    max-width: 30em;

    background-color: var(--color--gray--1);
    color: var(--color--white--1);
    box-shadow: 0 0 0.1em var(--card--shadow);
    
    .text {
        color: var(--color--white--1);
    }

    .help_button__text{
        color: var(--color--white--1) !important;
    }
    
    &.help_bubble--position--bottom::after {
        @include help_bubble_triangle_style;
        bottom: 100%;
        left: 50%;
        margin-left: calc( -1 * #{$help-bubble--triangle--width});
        border-color: transparent transparent var(--color--gray--1) transparent;
    }
    
    &.help_bubble--position--top::after {
        @include help_bubble_triangle_style;
        top: 100%;
        left: 50%;
        margin-left: calc( -1 * #{$help-bubble--triangle--width});
        border-color: var(--color--gray--1) transparent transparent transparent;
    }
    
    &.help_bubble--position--right::after {
        @include help_bubble_triangle_style;
        top: 50%;
        right: 100%;
        margin-top: calc( -1 * #{$help-bubble--triangle--width});
        border-color: transparent var(--color--gray--1) transparent transparent;
    }
    
    &.help_bubble--position--left::after {
        @include help_bubble_triangle_style;
        top: 50%;
        left: 100%;
        margin-top: calc( -1 * #{$help-bubble--triangle--width});
        border-color: transparent transparent transparent var(--color--gray--1);
    }
}