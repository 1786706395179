@import 'styles/general/variables.scss';

.da_section_access_code {
    justify-items: center;
    gap: 1.7em;
    padding: 1.7em 0em;
    border-radius: 1em;
}

@media only screen and (min-width: $size--laptop){
    .da_section_access_code {
        justify-items: center;
        gap: 1.7em;
        padding: 1.2rem 1.7rem 1.7rem 1.7rem;
        border-radius: 1em;
    }
}