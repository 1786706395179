.animation--zoomIn {
    animation-name: zoomIn;
    animation-timing-function: cubic-bezier(.87,-1.15,.95,1.25); 
    animation-duration: 0.6s;
}

@keyframes zoomIn {
    0% {
        transform: scale(0.85);
    }
    80% {
        transform: scale(1.06);
    }
    100% {
        transform: scale(1);
    }
}