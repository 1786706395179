.header {
    padding: 0.571rem 1.714rem 0.571rem 1.714rem;
    box-shadow: var(--card--shadow);
    background: rgb(var(--app--bg--color));
    z-index: 100;
    position: sticky;
    top: 0;

    .side_menu_bars {
        cursor: pointer;
        color: var(--text-color--third);
        font-weight: bold;
    }



    .header__side_menu_button_container{
        padding: 0.357em;
        &:hover{
            // background: var(--component--option--bg-color--hover);
            background: var(--component--option--bg-color--hover);
            border-radius: 6px;

            .header__side_menu_button_container__content{
                filter: var(--filter--active);
            }
        }
        .header__side_menu_button_container__content {
            width: 1.74em;
            filter: var(--filter--active);
    
            .lf-player-container{
                #lottie{
                    display: flex;
                }
            }
        }
    }
    

    img {
        width: 3.142em;
        height: 3.142em;
    }

    .header__button {
        background: var(--bg-color--second);
        border-radius: 8px;
        cursor: pointer;

        .user_info__default{
            width: 3.142em;
            height: 3.142em;
            filter: var(--filter--gray--5);
        }

        .account__user_photo {
            width: 3.142em;
            height: 3.142em;
            border-radius: 100%;
            object-fit: cover;
        }
    }

    a {
        display: flex;
    }
}
