@import 'styles/general/variables.scss';

.home_no_session {
    .logo_container {
        background-color: var(--color--black--4);
        justify-content: center;
        align-content: center;
        padding: 1em;

        .direct_access__button {
            position: sticky;
            top: 16px;
            z-index: 1;
            background-color: rgb(var(--app--bg--color-alter));
            color: var(--color--gray--1);
            border-radius: 40px;
            max-width: 14em;
            padding: 1em 1.42em;
            justify-self: end;
        }

        img {
            max-width: 300px;
        }
    }
}

@media only screen and (min-width: $size--laptop) {
    .home_no_session {
        overflow: hidden;
        grid-template-columns: 2.08fr 3fr auto;
    }

    .logo_container {
        .direct_access__button {
            display: none;
        }
    }
}