.animation--upDown {
    animation-name: upDown;
    animation-timing-function: ease;
    animation-duration: 0.6s;
    transform-origin: center;
}
.animation--downUp {
    animation-name: downUp;
    animation-timing-function: ease;
    animation-duration: 0.6s;
    transform-origin: center;
}



@keyframes upDown {
    0% {transform: translateY(-4%);}
    25% {transform: translateY(4%);}
    50% {transform: translateY(-4%);}
    75% {transform: translateY(4%);}
    100% {transform: translateY(0);}
}

@keyframes downUp {
    0% {transform: translateY(4%);}
    25% {transform: translateY(-4%);}
    50% {transform: translateY(4%);}
    75% {transform: translateY(-4%);}
    100% {transform: translateY(0);}
}