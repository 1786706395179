@import 'styles/general/variables.scss';

.button {
    border: 2px solid var(--component--color);

    &:hover {
        border-color: var(--component--color--active);
    }

    &.button--variant--filled {
        color: rgb(var(--app--bg--color));
        background-color: var(--component--color);

        &:hover {
            background-color: var(--component--color--active);
        }

        &.button--color--error {
            background-color: var(--color--red--1);
        }
    }

    &.button--variant--outlined {
        color: var(--component--color);
        background-color: transparent;

        &:hover {
            color: var(--component--color--active);
            border-color: var(--component--color--active);
        }

        &.button--color--error {
            color: var(--color--red--1);
            border-color: var(--color--red--1);
        }
    }
}