.company_logos_container{

    .add_company_logo__container{
        background: rgb(var(--app--bg--color-alter));
        border: solid 1px rgb(var(--app--bg--color-alter));
        border-radius: 1.14rem;
        height: 16.357em;
        max-width: 43.57rem;

        &:hover{
            background: rgb(var(--app--bg--color));
            border: solid 1px var(--component--color--active);
            cursor: pointer;

            .company_logo__change_photo{
                z-index: 2;
                background: white;
                opacity: 80%;
                visibility: visible !important;
                border-radius: 1.14rem;
            }
        }

        .company_logo_input{
            width: 100%;
            height: 16.357em;
            position: absolute;
            z-index: 3;
            opacity: 0;
            cursor: pointer;
        }

        .company_logo_photo{
            width: 100%;
            height: 16.357em;
            object-fit: contain;
            border-radius: 1.14rem;
        }

        .company_logo__personalized{
            position: absolute;
            width: 100%;
            background: rgb(var(--app--bg--color));
            border-radius: 1.14rem;
            .company_logo__change_photo{
                position: absolute;
                height: 16.357em;
                width: 100%;
                justify-items: center;
                align-content: center;
                justify-self: center;
                // border-radius: 100%;
                visibility: hidden;
            }
        }
    
        .edit_company_logo{
            justify-self: end;
            width: 1.5rem;
            height: 1.5rem;
            &:hover{
                filter: var(--filter--green--2) !important;
                cursor: pointer;
            }
        }
    }

    .add_company_logo__container_personalized {
        background: rgb(var(--app--bg--color-alter));
        border: solid 1px var(--color--gray--5);
        border-radius: 1.14rem;
        height: 16.357em;
        max-width: 43.57rem;
        box-shadow: 0px 1.65px 0px 0px var(--color--gray--5);
        -webkit-box-shadow: 0px 1.65px 0px 0px var(--color--gray--5);
        -moz-box-shadow: 0px 1.65px 0px 0px var(--color--gray--5);

        &:hover{
            background: rgb(var(--app--bg--color));
            border: solid 1px var(--component--color--active);
            cursor: pointer;
            box-shadow: 0px 1.35px 0px 0px var(--component--color--active);
        -webkit-box-shadow: 0px 1.35px 0px 0px var(--component--color--active);
        -moz-box-shadow: 0px 1.35px 0px 0px var(--component--color--active);

            .company_logo__change_photo{
                z-index: 2;
                background: white;
                opacity: 80%;
                visibility: visible !important;
                border-radius: 1.14rem;
                
            }
        }

        .company_logo_input{
            width: 100%;
            height: 16.357em;
            position: absolute;
            z-index: 3;
            opacity: 0;
            cursor: pointer;
        }

        .company_logo_photo{
            width: 100%;
            height: 16.357em;
            object-fit: contain;
            border-radius: 1.14rem;
        }

        .company_logo__personalized{
            
            position: absolute;
            
            width: 100%;
            background: rgb(var(--app--bg--color));
            border-radius: 1.14rem;
            .company_logo__change_photo{
                position: absolute;
                height: 16.357em;
                width: 100%;
                justify-items: center;
                align-content: center;
                justify-self: center;
                // border-radius: 100%;
                visibility: hidden;
            }
        }
    
        .edit_company_logo{
            justify-self: end;
            width: 1.5rem;
            height: 1.5rem;
            &:hover{
                filter: var(--filter--green--2) !important;
                cursor: pointer;
            }
        }
    }

    .add_company_icon__container{
        background: rgb(var(--app--bg--color-alter));
        border: solid 1px var(--color--gray--5);
        border-radius: 8.57rem;
        width: 8.57rem;
        height: 8.57rem;
        padding: 0.92em;
        justify-self: center;
        top: -78px;
        z-index: 4;

        &:hover{
            background: rgb(var(--app--bg--color));
            border: solid 1px var(--component--color--active);
            cursor: pointer;

            .company_icon__change_photo{
                z-index: 4;
                background: white;
                opacity: 80%;
                border: solid 1px rgb(var(--component--input--border-color--active));
                visibility: visible !important;
            }
        }

        .company_icon_input{
            width: 8.57rem;
            height: 8.57rem;
            position: absolute;
            z-index: 5;
            opacity: 0;
            cursor: pointer;
        }

        .edit_company_icon{
            justify-self: center;
            &:hover{
                filter: var(--filter--green--2) !important;
                cursor: pointer;
            }
        }

        .company_icon__personalized{
            position: absolute;
            background: rgb(var(--app--bg--color));
            border-radius: 100%;
        
            .company_icon__change_photo{
                position: absolute;
                padding: 0.92em;
                width: 8.57em;
                height: 8.57em;
                gap: 0.15em;
                justify-items: center;
                align-content: center;
                justify-self: center;
                border-radius: 100%;
                visibility: hidden;
            }
        }

        .company_icon_photo{
            width: 8.57em;
            height: 8.57em;
            object-fit: cover;
            border-radius: 100%;
        }
    }
    .add_company_icon__container_personalized{
        background: rgb(var(--app--bg--color-alter));
        border: solid 1px var(--color--gray--5);
        border-radius: 8.57rem;
        width: 8.57rem;
        height: 8.57rem;
        padding: 0.92em;
        justify-self: center;
        top: -78px;
        z-index: 4;
        box-shadow: 
            1.55px  0px 0px var(--color--gray--5),
            0px 1.5px 0px 0px var(--color--gray--5),
            1.55px 1.5px 0px 0px var(--color--gray--5);
        &:hover{
            background: rgb(var(--app--bg--color));
            border: solid 1px var(--component--color--active);
            cursor: pointer;
            box-shadow: 
            1.55px  0px 0px var(--component--color--active),
            0px 1.5px 0px 0px var(--component--color--active),
            1.55px 1.5px 0px 0px var(--component--color--active);

    

            .company_icon__change_photo{
                z-index: 4;
                background: white;
                opacity: 80%;
                border: solid 1px rgb(var(--component--input--border-color--active));
                visibility: visible !important;
            }
        }

        .company_icon_input{
            width: 8.57rem;
            height: 8.57rem;
            position: absolute;
            z-index: 5;
            opacity: 0;
            cursor: pointer;
        }

        .edit_company_icon{
            justify-self: center;
            &:hover{
                filter: var(--filter--green--2) !important;
                cursor: pointer;
            }
        }

        .company_icon__personalized{
            position: absolute;
            background: rgb(var(--app--bg--color));
            border-radius: 100%;
        
            .company_icon__change_photo{
                position: absolute;
                padding: 0.92em;
                width: 8.57em;
                height: 8.57em;
                gap: 0.15em;
                justify-items: center;
                align-content: center;
                justify-self: center;
                border-radius: 100%;
                visibility: hidden;
            }
        }

        .company_icon_photo{
            width: 8.57em;
            height: 8.57em;
            object-fit: cover;
            border-radius: 100%;
        }
    }
}

.company_form{
    margin-bottom: 250px;
}