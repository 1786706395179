.admin_company_info_page {
    .company_info__module_card {
        border: 1px solid var(--color--gray--5);

        &:not(.token) {
            cursor: pointer;

            &:hover {
                background-color: var(--component--option--bg-color--hover);
            }
        }
    }
}