@import 'styles/general/variables.scss';

.recover_password{
    padding: 0rem 1.2rem 1rem 1.2rem;
}

@media only screen and (max-width: $size--tablet){
    .recover_password {
        max-width: fit-content !important;
    }
}

@media only screen and (min-width: $size--laptop){
    .recover_password {
        padding: 0rem 1.2rem;
    }
}