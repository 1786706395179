//--------------------------------ScreenSizes--------------------------------

$size--tablet: 480px;
$size--laptop: 768px;
$size--desktop: 1024px;
$size--tv: 1440px;
$size--4k: 2500px;

//--------------------------------ZIndexes--------------------------------
$index--combobox_list: 1000;
$index--tooltip: 1000;
$index--loading: 10000;
$index--message-box: 11000;

//--------------------------------TransitionParams--------------------------------
$bezier--1: cubic-bezier(0.175, 0.885, 0.32, 1.75);