@import '/src/styles/general/general_functions.scss';
@import '/src/styles/general/variables.scss';
@import './text_field_colors.scss';

.text_field_container {
    display: flex;
    flex-direction: column;
    padding: 0;

    &:not(.no_label) {
        margin-top: 0.5em;
    }

    &.no_label {
        .text_field .text_field__fieldset .text_field__legend {
            max-width: 0px;
            padding: 0;
        }
    }

    &.label_up:not(.no_label) {
        .text_field__label {
            transform: translate(1.2em, -0.8em);
            font-size: getTextSize(2);
        }

        .text_field {
            .text_field__fieldset .text_field__legend {
                padding-inline: 5px;
                max-width: 100%;
                transition: max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms;
            }
        }
    }

    .text_field__label {
        font-weight: 500;
        line-height: 1.6em;
        display: block;
        transform-origin: top left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(80% - 10px);
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(1em, 1em);
        transition: font-size 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
        z-index: 1;
        pointer-events: none;
    }

    .text_field {
        cursor: text;
        display: flex;
        align-items: center;
        // border-radius: 4px;

        .text_field__input {
            font-size: getTextSize(3);
            letter-spacing: inherit;
            border: 0;
            box-sizing: content-box;
            background: none;
            height: 1.75em;
            margin: 0;
            -webkit-tap-highlight-color: transparent;
            display: block;
            min-width: 0;
            width: 100%;
            padding: 0.7em 1em;
            outline: none;
            font-weight: 500;
        }

        .text_field__input::-webkit-outer-spin-button,
        .text_field__input::-webkit-inner-spin-button {
            -webkit-appearance: none !important;
        }

        .text_field__fieldset {
            text-align: left;
            position: absolute;
            bottom: 0;
            right: 0;
            top: -5px;
            left: 0;
            margin: 0;
            padding: 0 8px;
            pointer-events: none;
            border-radius: 0.6em;
            border-style: solid;
            border-width: 1px;
            overflow: hidden;
            min-width: 0%;

            .text_field__legend {
                display: block;
                width: auto;
                padding: 0;
                height: 1em;
                font-size: getTextSize(2);
                visibility: hidden;
                max-width: 0px;
                transition: max-width 50ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
            }
        }
    }
}


@media screen and (min-width: $size--laptop) {
    .text_field_container {
        &.label_up:not(.no_label) {
            .text_field__label {
                transform: translate(1em, -0.5em);
            }
        }

        .text_field__label {
            line-height: 0.9em;
            transform: translate(1em, 0.85em);
        }

        .text_field {
            .text_field__input {
                height: 1.172rem;
                padding: 0.7rem 0.8571rem;
            }
        }
    }
}