@import '/src/styles/general/variables.scss';

input[type="color"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    padding: 0;
    width: 46px;
    height: 46px;
    margin: 0;
    display: flex;
    outline: none;
    writing-mode: lr !important;
    color: transparent;
    border-radius: 0.5em;
    background-color: rgb(145, 145, 150);
    overflow: hidden;
    cursor: pointer;

    &::-webkit-color-swatch {
        border: none;
        // border-radius: 0.5em;
        margin: 0;
        padding: 0;
    }

    &::-moz-color-swatch {
        border: none;
        margin: 0;
        padding: 0;
    }

    &::-webkit-color-swatch-wrapper {
        border: none;
        margin: 0;
        padding: 0;
    }

    &::-moz-color-swatch-wrapper {
        border: none;
        margin: 0;
        padding: 0;
    }

    @media screen and (min-width: $size--laptop) {
        width: 34px;
        height: 34px;
    }
}