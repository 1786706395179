@import 'styles/general/variables.scss';
.company_theme_container {
    flex-wrap: wrap;

    .company_theme__form_container {
        // padding: 0 1.8rem;
    }

    .company_theme__preview_container {
        padding: 1.71rem;
        border-radius: 0.57rem;
        border: 1px solid var(--color--gray--3);
        opacity: 1;
        transition: width 0.3s, opacity 0.3s;
        overflow-x: hidden;

        &.hide_content {
            width: 0;
            height: 0;
            padding: 0;
            opacity: 0;
            border: none;
        }

        .fake_button {
            font-size: var(--text-size--2);
            padding: 3px 12px;
            &:hover {
                background-color: var(--component--color);
                border-color: var(--component--color);
            }

            &.hovered,
            &.hovered:hover {
                background-color: var(--component--color--active);
                border-color: var(--component--color--active);
            }
        }
    }

    .button {
        justify-self: center;
    }

    .company_theme__rever_theme_btn {
        justify-self: center;
        border-radius: 0.5rem;
        padding: 0.7rem;
        cursor: pointer;

        &:hover {
            background-color: var(--component--option--bg-color--hover);
        }

        .icon {
            margin-left: 1rem;
        }
    }
}

.dark .company_theme_container {
    .company_theme__rever_theme_btn {
        &:hover .text {
            color: var(--component--color);
        }
    }
}