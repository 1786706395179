@import 'styles/general/general_functions.scss';

@mixin text_elipsis {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

h1,
h2,
h3 {
    &.text {
        font-weight: bold;
        color: RGB(var(--color--black--4));
    }
}

.text {
    color: rgb(var(--text--color--first));
    text-align: left;
    font-size: getTextSize(3);
    font-family: var(--font-family--main);
}

.text {
    @include text_props();
}

.text {
    .icon {
        margin: 0.3em 0.5em -0.5em 0.5em;
        width: 1.8em;
        height: 1.8em;
    }
}