@import 'src/styles/general/variables.scss';

.processing_container {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    place-content: center;
    place-items: center;
    top: 0;
    left: 0;
    padding: 0 1rem;
    z-index: $index--loading;
    background-color: rgba(var(--app--bg--color), 0.4);

    &.is_loading {
        display: grid;
    }

    .animation {
        max-width: 25em;
        max-height: 60vh;
    }
}