.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: rgba(var(--app--bg--color), 0.4);
    z-index: 1000;

    .modal_content {
        box-shadow: var(--card--shadow);
        border-radius: 1rem;
        background-color: rgb(var(--app--bg--color));
    }
}