@import 'styles/general/general_functions.scss';
@import 'styles/general/variables.scss';
@import './button_colors.scss';

.button {
    font-weight: bold;
    padding: 1.2857em 1.7142em;
    border-radius: 35px;
    font-size: getTextSize(3);
    line-height: 16px;
    cursor: pointer;
    height: fit-content;

    &:focus {
        outline: none;
    }
}

@media only screen and (min-width: $size--laptop) {
    .button {
        padding: 0.458rem 0.9rem;
    }
}