@import 'styles/general/variables.scss';
@import 'styles/general/general_functions.scss';
@import 'styles/general/general_states.scss';
@import './combobox_colors.scss';

$default--size: 3;
$default--label--size: 2;
$border-width: 1px;
$padding-top-bottom: 0.7em;

/*---------------------------------COMBOBOX---------------------------------------*/
.combobox__container {
    align-self: start;
    @include state__hidden;
    @include state__disabled;

    &:not(.no_label) {
        margin-top: 0.5em;
    }

    .combobox {
        font-size: getTextSize($default--size);
        padding: 1em;
        cursor: pointer;

        /*---------------------------------VALUE---------------------------------------*/
        .combobox__text {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            user-select: none;
            font-size: getTextSize($default--size);
            font-weight: 500;
        }
    }


    /*--------------------------------------LIST---------------------------------------*/
    .combobox__list {
        position: fixed;
        overflow: hidden;
        overflow-y: auto;
        z-index: $index--combobox_list;
        box-shadow: var(--card--shadow);
        border-radius: 0.5em;
        animation: ComboBoxListAnimationOpen 0.3s;
        max-height: 20em;
    }

    .combobox__list--invisible {
        border-width: 0;
        animation: ComboBoxListAnimationClose 0.3s;
        max-height: 0;
        overflow: hidden;
        box-shadow: none;
    }

    /*-----------------------------OPTIONS----------------------------------*/
    .combobox__list__option {
        cursor: pointer;
        list-style: none;
        padding: 1em;
        user-select: none;
    }

    /*---------------------------------LABEL---------------------------------------*/
    &.floating_label:not(.label_up) .combobox__text {
        visibility: hidden;
    }

    &.no_label {
        .combobox .combobox__fieldset .combobox__legend {
            max-width: 0px;
            padding: 0;
        }
    }

    .combobox__label {
        font-weight: 500;
        line-height: 1.6em;
        display: block;
        transform-origin: top left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(80% - 10px);
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(1.5em, 1em);
        transition: font-size 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
        z-index: 1;
        pointer-events: none;
    }

    .combobox__fieldset {
        text-align: left;
        position: absolute;
        bottom: 0;
        right: 0;
        top: -5px;
        left: 0;
        margin: 0;
        padding: 0 8px;
        pointer-events: none;
        border-radius: 0.6em;
        border-style: solid;
        border-width: 1px;
        overflow: hidden;
        min-width: 0%;

        .combobox__legend {
            display: block;
            width: auto;
            padding: 0;
            height: 1em;
            font-size: getTextSize(2);
            visibility: hidden;
            max-width: 0px;
            transition: max-width 50ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
        }
    }


    &.label_up:not(.no_label) {
        .combobox__label {
            transform: translate(1.2em, -0.75em);
            font-size: getTextSize(2);
        }

        .combobox {
            .combobox__fieldset .combobox__legend {
                padding-inline: 5px;
                max-width: 100%;
                transition: max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms;
            }
        }
    }
}

@media screen and (min-width: $size--laptop) {
    .combobox__container {
        .combobox {
            padding: 0.686em 1em;
        }

        /*-----------------------------OPTIONS----------------------------------*/
        .combobox__list__option {
            padding: 0.7em 1em;
        }

        &.label_up:not(.no_label) {
            .combobox__label {
                transform: translate(1em, -0.7em);
            }
        }

        .combobox__label {
            line-height: 1em;
            transform: translate(1em, 0.8em);
        }
    }
}

/*------------------------------ANIMATIONS---------------------------------------*/
@keyframes ComboBoxListAnimationOpen {
    0% {
        max-height: 0;
    }

    100% {
        max-height: 20em;
    }
}

@keyframes ComboBoxListAnimationClose {
    0% {
        max-height: 20em;
        border-width: 2px;
    }

    99% {
        border-width: 2px;
    }

    100% {
        max-height: 0;
        border-width: 0;
    }
}