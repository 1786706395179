@import './search_box_colors.scss';
@import 'styles/general/variables.scss';

.search_box {
    .search_box__list {
        display: grid;
        align-content: start;
        position: fixed;
        z-index: $index--combobox_list;
        max-height: 0em;
        overflow-x: hidden;

        border-radius: 0.5em;
        box-shadow: var(--card--shadow);
        visibility: hidden;
        transition: max-height 0.3s;

        &--open {
            min-height: 7em;
            max-height: 20em;
            visibility: visible;
        }

        .search_box__option {
            display: grid;
            gap: 0.5em;
            cursor: pointer;
            list-style: none;
            padding: 0.6em 0.8em;
            user-select: none;
        }
    }
}