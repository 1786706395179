@import '/src/styles/general/general_functions.scss';
@import './toggle_colors.scss';
@import '/src/styles/general/variables.scss';

.toggle_switch {
    display: flex;
    flex-wrap: wrap;
    user-select: none;

    .toggle_switch__label {
        margin-inline: 0.5em;
        font-size: getTextSize(2);

        &.label1 {}

        &.label2 {}
    }

    &--size-l {
        gap: 1em;
        margin: 4px 0 4px 0;

        .toggle_switch__switch {
            transform: scale(1.5);
        }
    }

    .toggle_switch__switch {
        position: relative;
        display: flex;
        width: 34px;
        height: 19px;
        cursor: pointer;
        border-radius: 1rem;

        .toggle_switch__slider {
            position: absolute;
            height: 15px;
            width: 15px;
            left: 2px;
            top: 2px;
            border-radius: 50%;
            transition: left 0.2s;
        }

        &.active {
            .toggle_switch__slider {
                left: 17px;
            }
        }
    }
}

@media screen and (min-width: $size--laptop){
    .toggle_switch{
        .toggle_switch__switch{
            width: 34.5px;
            height: 20px;
            .toggle_switch__slider{
                height: 16px;
                width: 16px;
                left: 3px;
                top: 2.04px;
            }
            &.active {
                .toggle_switch__slider {
                    left: 15.5px;
                }
            }
        }
    }
}