@import '/src/styles/general/variables.scss';
@import '/src/styles/general/colors.scss';

.message_box_modal {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(var(--app--bg--color),0.8);
    z-index: $index--message-box;

    .message_box {
        max-width: 40em;
        max-height: 60vh;
        border-radius: 1em;
        background-color: rgb(var(--app--bg--color));
        box-shadow: var(--card--shadow);
        margin: 0px 10px;

        p.text {
            text-align: center;
        }

        .message_box__title {
            font-size: 16px;
        }

        .message_box__content {
            overflow: auto;
            ul {
                padding-inline-start: 1.5rem;
            }
        }

        .message_box__animation {
            max-width: 28.57em;
            justify-self: center;
        }

        .message_box__buttons {
            gap: 1rem;
        }
    }
}