@import 'styles/general/variables.scss';

.login {
    
    

    .login__form {
        align-content: start;
    }

    .want_open_account {
        color: var(--color--green--1);
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
        
    }

    .want_open_account:hover {
        color: var(--color--green--2)
    }

    .password_forgotten{
        cursor: pointer
    }

    .password_forgotten:hover {
        color: var(--color--green--2)
    }
}

@media only screen and (min-width: $size--laptop) {
    .login {
        align-content: center;
        justify-self: center;
        max-width: 354px;

        .login__content{
            min-height: 97vh;
            .login__content__form{
                align-content: center;
            }
            .login__content__footer{
                position: absolute;
                align-self: end;
            }
        }
    }
}