.recover_password_form {
    form {
        width: 100%;
        display: grid;
        justify-items: center
    }

    .recover_password_form__form {
        // background: var(--bg-color--white);
        // border-radius: 16px;
    }
}