.combobox__container.fake_combobox {
    font-size: var(--text-size--2);
    max-width: 15.14rem;

    .combobox{
        cursor: default;
    }

    .combobox__label{
        font-size: 10px !important;
    }

    .combobox__fieldset {
        .combobox__legend{
            width: 150px;
        }
    }

    .combobox__text{
        font-size: var(--text-size--2);
    }
    /*----------------------------------------LIST----------------------------------------*/
    .combobox__list__option {
        cursor: default;
        &:hover {
            background-color: transparent;
        }
    }
}

.dark {
    .combobox__container.fake_combobox {
        .combobox__list__option {
            &:hover {
                color: inherit;
            }
        }
    }
}