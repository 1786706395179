

.password__wrapper{
    .error {
        display: flex
    }
    .error::before {
        content: "X";
        display: inline-flex;
        font-size: var(--text-size--3);
        font-weight: 650;
        color: var( --text--color--error);
        padding-right: 0.35em;
    }
    .success {
        display: flex
    }
    .success::before {
        content: "\2713";
        font-weight: 650;
        display: inline-flex;
        font-size: var(--text-size--3);
        color: var(--text--color--seventh);
        padding-right: 0.35em;
    }
}