@import 'styles/general/variables.scss';
@import 'styles/general/general_functions.scss';
@import 'styles/general/general_states.scss';

.side_menu {
    --side_menu__width: 56.71rem;
    --side_menu__animation_time: 0.5s;

    height: calc(100vh - 64px);
    position: fixed;
    z-index: 99;
    // top: 0;
    left: calc(-1 * var(--side_menu__width));
    max-width: var(--side_menu__width);
    width: 100%;

    box-shadow: var(--card--shadow);
    background-color: rgb(var(--app--bg--color));

    transition: left var(--side_menu__animation_time);

    grid-template-columns: 100% 100%;

    &.side_menu--open {
        left: 0;
        max-width: none;
    }

    &.side_menu--minimize {
        max-width: var(--side_menu__width);

        .side_menu__categories {
            display: none;
        }
    }
}


.side_menu__categories {
    padding-top: 1.2rem;
    .side_menu__categories__arrow_button {
        @include default_button;
        padding-top: 0.5em;
        box-shadow: var(--card--shadow);

        &:last-child {
            box-shadow: var(--card--shadow);
        }
    }

    .side_menu__categories__option {
        grid-template-columns: 1fr;
        padding: 0.715rem 1.35rem;
        &:hover{
            background-color: var(--component--option--bg-color--hover);
        }

        .side_menu__categories__option__inner_grid {

            cursor: pointer;
            border-radius: 0.7em;
            grid-gap: 2em;
            width: 100%;

            &.side_menu__categories__option__inner_grid--selected,
            &:hover {
                background-color: RGB(var(--color--third));
            }

            .side_menu__categories__option__inner_grid__icon {
                &.icon {
                    height: 5em;
                    width: 5em;
                }

                &.animation {
                    width: 2em;
                    filter: var(--filter--active);
                }
            }



            .side_menu__categories__option__inner_grid__text {
                // font-size: 1.3em;
            }
        }
    }
}


.side_menu .side_menu__content {
    display: none;

    &.side_menu__content--open {
        display: inherit;
        border-left: var(--card--shadow);
    }
}

@media screen and (min-width: $size--tablet) {
    .side_menu {
        grid-template-columns: 100%;
        width: auto;

        &.side_menu--open {
            width: 24.28rem;
        }

        &.side_menu--minimize {
            grid-template-columns: auto 1fr;
            width: 100%;

            .side_menu__categories {
                display: inherit;
                box-shadow: var(--card--shadow);

                .side_menu__categories__option {
                    padding: 0.67rem 1.30rem;
                    .side_menu__categories__option__inner_grid {
                        grid-gap: 0;
                    }

                    .side_menu__categories__option__inner_grid__text {
                        display: none;
                    }
                }
            }
        }

        &:not(.side_menu--minimize) {
            .side_menu__categories__option__inner_grid__tooltip {
                display: none !important;
            }
        }
    }

    .side_menu__categories {
        .side_menu__categories__option .side_menu__categories__option__inner_grid {
            grid-gap: 1.42rem;

            .side_menu__categories__option__inner_grid__text {
                // font-size: 1.1em;
            }

            .side_menu__categories__option__inner_grid__icon {
                &.icon {
                    height: 2.5em;
                    width: 2.5em;
                }

                &.animation {
                    width: 1.715rem;
                }
            }
        }
    }
}

// @media screen and (min-width: $size--tv){
//     .side_menu{
//         --side_menu__width: 70em;
//     }
// }

@media screen and (min-width: $size--tv) {
    .side_menu {
        --side_menu__width: 52vw;
    }
}