.radio_button {
    .radio_button__input {
        border: 2px solid var(--border-color--second-alter);

        &.checked {
            border-color: var(--input--border-color--primary);

            .radio_button__circle {
                background-color: var(--input--border-color--primary);
            }
        }

        .radio_button__circle {
            background-color: var(--bg-color--second-alter);
        }
    }
}