$slider-animation-duration: 0.3s;

.slider {
    overflow: hidden;
    max-width: 100vw;

    .slider__screen {
        will-change: left;
        transition: $slider-animation-duration;
        width: 100%;
        flex-shrink: 0;

        &:not(.open) {
            max-height: 0;
            overflow: hidden;
            // animation: noHeight 0s calc($slider-animation-duration * 0.8) forwards;
        }
    }
}

@keyframes noHeight {
    0% {}

    100% {
        max-height: 0;
        overflow: hidden;
    }
}