.change_password {
    padding: 0.5em;
    max-width: 260px;
    justify-self: center;
    form{
        width: 100%;
        display: grid;
        justify-items: center
    }
    img{
        width: 11.8em;
    }
}