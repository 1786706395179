@import 'styles/general/variables.scss';

.direct_access_menu {
    background-color: rgb(var(--app--bg--color-alter));
    position: fixed;
    width: 100%;
    align-content: start;
    min-height: 100vh;
    z-index: 1;
    left: 100%;
    transition: 1s;
    gap: 0.51em;
    padding: 1.5em 1.2em;

    &.movil_menu {
        left: 0;
        transition: 1s;
    }

    .direct_access_menu__back {
        max-width: 135px;
        background: var(--color--white--1);
        ;
        border-radius: 40px;
        gap: 0.6em;
        padding: 1em 1.42em;
    }

    .direct_access__title {
        margin: auto;
        padding: 1.2em 1em;
        border-bottom: 1px solid var(--color--gray--3);
        width: 100%;
        justify-content: center;    
    }

    .accordion_group {
        gap: 1.5em;
    }

    .direct_access__section_accordion {
        padding-top: 1.2em;
        border-radius: 1em;
        

        .direct_access__accordion_title {
            cursor: pointer;
            user-select: none;
            margin: auto;
            
            

            span {
                font-weight: bold;
                white-space: nowrap;
            }
        }

        .direct_access__accordion_title:hover{
            color: var(--color--green--2);
        }

        &.open {
            background-color: rgb(var(--app--bg--color));

            .direct_access__accordion_title {
                color: var(--color--green--2);
            }
        }
    }

}

@media only screen and (min-width: $size--laptop) {
    .direct_access_menu {
        display: grid;
        align-content: start;
        max-width: 13.428rem;
        transition: max-width 0.3s;
        background-color: rgb(var(--app--bg--color-alter));
        position: initial;
        padding: 1.08em 0em;

        .direct_access_menu__back {
            display: none;
        }

        .direct_access__title {
            margin: auto;
            padding: 1.2em 1em 1.14em 1em;
            border-bottom: 1px solid var(--color--gray--3);
            width: auto;
        }

        .accordion_group {
            // padding: 0.5em;
            gap: 0.51rem;
        }

        &:not(.open) .character_field {
            display: none;
        }

        &.open {
            max-width: 25em;
        }

        .direct_access__section_accordion {
            padding-top: 1.2em;
            border-radius: 0em;

            .direct_access__accordion_title {
                cursor: pointer;
                user-select: none;
                max-width: 12em;
                margin: auto;
                display: block;
                line-height: 14px;

                span {
                    font-weight: bold;
                    white-space: nowrap;
                }
            }

            &.open {
                background-color: rgb(var(--app--bg--color));

                .direct_access__accordion_title {
                    color: var(--color--green--1);
                }
            }
        }
    }
}