@import '/src/styles/general/variables.scss';

.idle_app_mask {
    z-index: $index--tooltip;
    width: 100vw;
    height: 100vh;
    position: fixed;
    align-items: center;
    justify-items: center;
    top: 0;
    left: 0;

    .idle_app_mask__content {
        width: 100%;
        height: 100%;
        max-width: 1000px;
        max-height: 640px;
        border-radius: 1rem;
        background-color: rgb(var(--app--bg--color));
        box-shadow: var(--card--shadow);
        overflow: hidden;

        .magiei_security_container {
            place-items: center;
            background-color: var(--color--blue--7);
        }

        .security_title_label {
            color: var(--color--blue--7);
        }

    }
}