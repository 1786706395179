@import 'styles/general/variables.scss';

.signup {
    margin-bottom: 4em;
    padding: 0em 1em;

    .span_main_color_clickeable {
        color: var(--color--green--1);
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
        text-align: center;
    }

    .span_main_color_already_account {
        display: block;
    }

}

@media only screen and (min-width: $size--laptop) {
    .signup {
        align-content: center;
        justify-self: center;
        padding: 0;
        margin: 0;

        form {
            width: 100%;
            display: grid;
            justify-items: center;
        }

        .span_main_color_clickeable {
            display: inline;
        }

        .span_main_color_clickeable:hover {
            color: var(--color--green--2);
        }
    }
}