.profile_menu {
    right: 2em;
    position: fixed;
    box-shadow: var(--card--shadow);
    width: 100%;
    max-width: 300px;
    transition: top 0.3s cubic-bezier(0, 0.33, 0.58, 1);
    top: -50em;
    background: rgb(var(--app--bg--color));
    z-index: -1;
    border-radius: 8px;

    &.open {
        top: 60px;
        z-index: 100;
    }

    .user_info__default {
        width: 4.57em;
        height: 4.57em;
        filter: var(--filter--gray--5);
    }

    .account__user_photo {
        width: 4.57em;
        height: 4.57em;
        border-radius: 100%;
        object-fit: cover;
    }

    .profile_menu__option {
        width: 100%;
        text-align: center;
        padding: 0.42em 0em;

        &:hover {
            background-color: var(--component--option--bg-color--hover);
            color: var(--component--color);
            border-radius: 4px;
            cursor: pointer;
        }
    }

    .dark_mode_toggle {
        align-items: center;

        .toggle_switch__label {
            display: grid;
            grid-auto-flow: column;
            gap: 0.5rem;
            align-items: center;

            &.label1 {
                margin-left: 0;
            }

            &.label2 {
                margin-right: 0;
            }
        }
    }
}

.dark {
    .profile_menu {
        .profile_menu__option {
            &:hover {
                // color: rgb(var(--app--bg--color));
                color: var(--component--color);
            }
        }
    }
}