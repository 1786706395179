.admin__side_menu {
    position: sticky;
    top: 0;
    height: 100vh;
    background-color: var(--color--gray--5);
    
    .side_menu__section {
        padding: 1rem;
        min-height: 100px;
        cursor: pointer;

        &.active .text {
            color: var(--component--color);
        }

        &:hover {
            background-color: var(--component--option--bg-color--hover);
        }
    }
}