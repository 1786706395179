@import 'styles/general/colors.scss';

.account {
    .password_confirm_mask {
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(50, 50, 50, 0.1);
        z-index: 1000;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        .password_confirm_mask__content {
            background-color: rgb(var(--app--bg--color));
            border-radius: 8px;
        }
    }

    .account__user_info {

        .user_info__default {
            width: 8.857rem;
            height: 8.857rem;
            background-color: rgb(var(--app--bg--color-alter));
            border-radius: 100%;
            align-content: center;
            justify-items: center;
            gap: 0.37em;
        }

        .user_info__change_photo {
            position: absolute;
            width: 8.857rem;
            height: 8.857rem;
            justify-items: center;
            align-content: center;
            border-radius: 100%;
            gap: 0.37em;
        }

        .account__user_info__photo{
            .user_info__default{
                border: solid 1px var(--color--gray--5);
                
            }
        }

        .account__user_info__photo:hover {
            cursor: pointer;

            .user_info__default {
                background: rgb(var(--app--bg--color));;
                border: solid 1px var(--component--color--active);
            }

            .user_info__change_photo {
                z-index: 1;
                background: white;
                opacity: 80%;
                border: solid 1px var(--component--color--active);
            }
        }

        .account__user_photo {
            width: 8.857rem;
            height: 8.857rem;
            border-radius: 8px;
            object-fit: cover;
            border-radius: 100%;
        }

        .account__user_input_photo {
            width: 8.857rem;
            height: 8.857rem;
            position: absolute;
            z-index: 2;
            opacity: 0;
            cursor: pointer;
        }

        .change_password__container{
            .change_password{
                max-width: 19.5rem;
            }
        }
    }

    .account__img_container {
        cursor: pointer;
        justify-items: center;
        border-radius: 8px;
        background-color: var(--bg-color--blue-alter);
    }

    // .change_pin__text:hover{
    //     color: var(--color--green--2);
    //     cursor: pointer;
    // }

    .account__logout {
        border: solid 2px var(--border-color--white);
        // background: transparent;
        margin-top: 0.46em;
    }

    .account__total_bold {
        font-weight: bold;
        font-size: 16px;
    }
}